$(() => {
  const isNative =
    window.navigator.userAgent.toLowerCase().match(/makuake-ios$/i) ||
    window.navigator.userAgent.toLowerCase().match(/makuake-android$/i);

  function isSpBrowserIos() {
    return (
      !isNative &&
      window.navigator.userAgent.toLowerCase().match(/iphone os (\d*)/)
    );
  }

  function isSpBrowserAndroid() {
    return (
      !isNative && window.navigator.userAgent.toLowerCase().match(/android/i)
    );
  }

  if (isSpBrowserIos()) {
    $('#footer_app_banner').show();
    return;
  }
  if (isSpBrowserAndroid()) {
    $('#footer_android_banner').show();
    return;
  }

  if (!isNative && window.navigator.userAgentData) {
    const getPlatformFromUaCh = function () {
      return window.navigator.userAgentData.getHighEntropyValues(['platform']);
    };
    getPlatformFromUaCh().then(result => {
      if (result.platform === 'iOS') {
        $('#footer_app_banner').show();
      }
      if (result.platform === 'Android') {
        $('#footer_android_banner').show();
      }
    });
  }
});
